.auth-boxes > *{
  box-sizing: border-box;
}

.auth-boxes > div{
  background-color: #EAEDEC;
  border: #999090 solid 2px;
}
  
.auth-boxes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
  
.Login-register-admin{
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 2vw;
  text-align: left;
  width: 70%;
  padding-bottom: 2%;
}

.Login-register-admin > p{
 text-align: center;
 line-height: normal;
 margin: auto;
}

.mini-login-header{
 text-align: center;
 margin-top: 2%;
 font-size: 6.5vw;
 margin-top: 3%;
 margin-bottom: 0;
}

.mini-login-label, .mini-login-input{
 display: block;
 margin-right: auto;
 margin-left: auto;
 width: 70%;
 margin-bottom: 1%;
 background-color: 999090;
}
  
.mini-login-label{
  font-size: 5vw;
  margin-top: 4%;
}

.mini-login-input{
  font-size: 5vw;
}

.mini-login-register, .mini-login-admin{
  font-size: 4.5vw;
  padding-top: 2%;
}


.mini-login-link{
  display: block;
  color: blue;
  margin-top: 2%;
}

.mini-login-link:hover{
  text-decoration: underline;
}

.mini-login-submit{
  text-align: center;
  font-size: 5vw;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10%;
  border: #999090 solid 1px;
  background-color: white;
}

.mini-login-submit:hover{
  background-color: #999090;
  transition: 0.3s;
  cursor: pointer;
}

.mini-login-submit:active{
  border-top: grey solid 2px;
  background-color: #999090;
  padding-top: 0.5%;
}

.mini-login-register-heading{
  font-size: 5vw;
  width: 90%; 
  margin-left: auto;
  margin-right: auto;
}

.additional-info{
    display: none;
}

.additional-info > p{
  font-size: 2vw;
  text-align: center;
}
  

.additional-info-mobile{
  width: 70%;
  text-align: center;
  padding-bottom: 5%;
  margin-top: 3%;
  margin-bottom: 4%;
}

.additional-info-mobile > *{
  font-size: 4.5vw;
  line-height: 2.5vw;
}

.additional-info > img{
  height: 3vw;
  width: 3vw;
}

.image-link-container-mobile > a{
  margin-left: 2vw;
  font-weight: bold;
  color: blue;
}

.auth-box-mobile-link:hover{
  text-decoration: underline;
}

.auth-box-break{
  display: block;
  margin-top: 1%;
  padding: 0;
}

.mini-login-admin{
  padding-top: 1vw;
}

.github, .linkedIn{
  width: 30%;
  margin: 10%;
}

.image-link-container{
  text-align: center;
  width: 100%;
  display: block;
}

.portfolio-link{
  font-weight: bold;
  color: blue;
  margin-top: 1rem;
  margin-bottom: 0;
}

.portfolio-link:hover{
  text-decoration: underline;
}

@media screen and (min-width: 1000px) {
  .auth-boxes.mobile{
    display: none;
  }
  
  .Login-register-admin{
    font-size: 2vw;
    text-align: left;
    width: 50%;
    padding-bottom: 2%;
    height: 37vw;
    min-height: 430px;
    max-height: 680px;
  }

  .additional-info{
    display: block;
    min-height: 430px;
    height: 37vw;
    max-height: 680px;
    width: 30%;
  }


  .mini-login-header{
    font-size: 3vw;
   }

   .mini-login-label{
    font-size: 2.2vw;
  }
  
  .mini-login-input{
    font-size: 2.5vw;
  }

  .mini-login-register, .mini-login-admin{
    font-size: 2vw;
    padding-top: 0;
  }

  .mini-login-submit{
     font-size: 2.2vw;
     
  }
}

@media screen and (min-width: 1800px) {
  .additional-info > p{
    font-size: 34px;
    text-align: center;
  }

  .mini-login-header{
    font-size: 45px;
   }

   .mini-login-label{
    font-size: 40px;
  }
  
  .mini-login-input{
    font-size: 40px;
  }

  .mini-login-register, .mini-login-admin{
    font-size: 40px;
    padding-top: 0;
  }

  .mini-login-submit{
    font-size: 40px;
    padding: 5px;   
  }
  
}