.view-my-posts-link{
  color: black;
  top: 10%;
  text-decoration: none;
  position: relative;
  top: 8vw
}

.view-my-posts-post-box{
  margin-top: 12%;
  margin-bottom: 5%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  align-content: space-around;
  flex-wrap: wrap;
}

.view-my-posts-create-post{
  text-align: center;
  margin-top: 10%;
}

.view-my-posts-create-post-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 4vw;
  margin-bottom: 8%;
  background-color: rgba(237, 247, 102, 0.952);
  border-radius: 10%;
  border: 1px solid #3c484d;
  font-weight: bold;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.view-my-posts-create-post-button:hover{
  background-color: #996060;
  transition: 0.5s;
  cursor: pointer;
}


.view-my-posts-link{
  color: black;
  text-decoration: none;
}


@media screen and (min-width: 1000px) {
  .view-my-posts-post-box{
      margin-top: 6%;
  }

  
  .view-my-posts-create-post-button{
    font-size: 2.5vw;
    padding-top: 1%;
    padding-left: 2%;
    padding-bottom: 1%;
  }
}