   .view-posts-post{
    width: 40%;
    border-radius: 20px;
    margin: 1%;
    margin-top: 6%;
    background-color: rgba(237, 247, 102, 0.952);
    border: #999090 1px solid;
  }

  .view-posts-car-name{
    text-align: center;
    width: 100%;
    font-size: 3.5vw;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: bold;
  }
  
  .view-posts-car-image-box{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 35%;
  }

  .view-posts-car-image{
    width: 100%;
    border: #999090 1px solid;
  }

  .view-posts-post > ul{
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0;
    width: 100%;
 }

  .view-posts-lists  li{
    font-family: 'Merriweather Sans', sans-serif;
  }

  ul .view-posts-car-location{
    font-size: 3vw;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: -3px;
    margin-bottom: 0;
  }

  ul .view-posts-car-price{
    font-size: 5vw;
    margin: 5%;
  }

  ul .view-posts-car-commission{
    font-size: 4vw;
    padding-left: 5%;
    padding-right: 5%;
    margin: 1%;
    margin-bottom: 10%;
  }

  .view-post_commision-title{
    display: block;
    font-family: 'Merriweather Sans', sans-serif;
    text-decoration: underline;
  }
  
  .view-posts-lists > li{
      font-size: 3vw;
      margin-bottom: 1%;
  }

  .view-posts-post{
    text-decoration: none;
    color: black;
  }

  .post-link{
    height: auto;
    width: auto;
  }

  @media screen and (min-width: 1000px) {
    .view-posts-post{
      width: 25%;
    }

    .view-posts-post:hover{
      background-color: yellow;
      position: relative;
      bottom: 10px;
      transition: 1s;
    }

    .view-posts-car-name{
      font-size: 30px;
      margin-bottom: 3%;
    }

    .view-posts-lists > li{
      font-size: 30px;
    }

    ul .view-posts-car-price{
      font-size: 40px;
      margin: 0;
    }

    ul .view-posts-car-commission{
      font-size: 30px;
    }

    ul .view-posts-car-location{
      font-size: 20px;
    }
  }