.landing-page{
  margin-top: 11%;
}

.landing-page > section{
  padding: 1.5%;
  width: 95%;
  max-width: 1200px;
  margin: 5% auto;
  background-color: rgba(237, 247, 102, 0.952);
  border: #886060 0.5px solid;
  border-radius: 100px;
}

.landing-page-link{
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  
  .landing-page > section{
    padding: 0.2%;
  }

  .landing-page{
    margin-top: 11%;
  }
    
}

@media screen and (min-width: 1400px) {
  .landing-page{
    margin-top: 0;
  }
}