.hero{
    position: relative;
    margin-top: 15%;
    height: 48vw;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: none !important;
}

.landing-page-get-started-link-mobile{
    text-align: center;
    margin: auto;
    padding: 2%;
    position: absolute;
    left: 40%;
    width: 20%;
    text-align: center;
    font-size: 2.5vw;
    background-color: #886060;
    border-radius: 30%;
    border: 1px solid black;
    z-index: 8;
}

.landing-page-get-started-link{
    display: none;
}

.landing-page-get-started-link:hover{
    color: #886060;
    background-color: yellow;
}

.salesman{
    max-height: 550px;
    height: 45vw;
    position: absolute;
    bottom: 0;
    z-index: 3;
    left: 20%;
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 0.8s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

.yellow-car{
    max-height: 400px;
    height: 30vw;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: -60%;
    animation-name: car-drives-in;
    animation-duration: 3s;
    animation-timing-function: ease-out;
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
}

@keyframes fade-in {
    from{ left: 17%; }
    to { left: 20%; opacity: 1;}
}

@keyframes car-drives-in {
    from{ right: -60%;}
    to { right: 20%; }
}

@keyframes emerge {
    from { bottom: 8%; }
    to { bottom: 80%; opacity: 1; }
}

@media screen and (min-width: 1000px) {
  
    .hero{
        margin-top: 10%;
    }

    .landing-page-get-started-link-mobile{
        display: none;
    }

    .landing-page-get-started-link{
        display:  block;
        text-align: center;
        margin: auto;
        padding: 2%;
        z-index: 1;
        position: absolute;
        opacity: 0;
        right: 40%;
        width: 20%;
        font-size: 2.5vw;
        background-color: #886060;
        border-radius: 30%;
        animation-name: emerge;
        animation-duration: 3s;
        animation-delay: 3s;
        animation-fill-mode: forwards;
        transition: 1s;
        font-family: 'Merriweather Sans', sans-serif;
        border: 1px solid black;
    }
}

@media screen and (min-width: 1600px) {
    @keyframes emerge {
        from { bottom: 8%; }
        to { bottom: 60%; opacity: 1; }
    }

    .hero{
        margin-top: 0;
    }
}