.login-box{
    margin-top: 12%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10vw;
    width: 85%;
    max-width: 500px;
    text-align: center;
    border: #999090 solid 2px;
    background-color: #EAEDEC;
    border-radius: 10%;
}

.login-box > *{
    font-size: 5vw;
}

.login-header-box{
    text-align: center;
    position: relative;
    margin-bottom: 2%;
}

.login-header{
    display: block;
    font-size: 8vw;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}


.login-label, .login-input{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}

.login-label{
    margin-bottom: 2%;
    font-size: 5.5vw;
}

.login-input{
    margin-bottom: 5%;
    margin-top: 0;
    width: 60%;
    font-size: 4.5vw;
}
  
.login-submit{
    margin: 2% auto;
    width: 35%;
    height: 8vw;
    max-height: 55px;
    vertical-align: middle;
    font-size: 4vw;
    border-radius: 10%;
    border: #999090 solid 1px;
    background-color: white;
    cursor: pointer;
}

.login-submit:hover{
    background-color: #999090;
    transition: 0.3s;
}

.login-submit:active{
    border-top: grey solid 2px;
    padding-top: 0.5%;
}

.login-register-heading{
    font-size: 5vw;
    width: 90%; 
    margin-left: auto;
    margin-right: auto;
}

.login-register-link{
    display: block;
    margin-top: 2%;
    color: blue;
}

.login-register-link:hover{
    text-decoration: underline;
}

.login-error{
    color: red;
    font-size: 4vw;
    margin: 0;
    margin-bottom: 5%;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
  
@media screen and (min-width: 1000px) {
  
      .login-box > *{
        font-size: 30px;
      }
  
      .login-header{
        font-size: 55px;
      }

      .login-label, .login-input{
        font-size: 30px;
      }

      .login-submit{
        font-size: 30px;
    }
}