
.make-post{
    margin-top: 11%;
}

.make-post-heading{
    font-size: 7vw;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}

.make-post-form{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    padding-bottom: 2%;
    position: relative;
    background-color: #EAEDEC;
    border: #999090 solid 2px;
    border-radius: 10%;
}

.make-post-input-box{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
}


.make-post-right, .make-post-left{
    width: 100%;
    min-width: 250px;
    margin: 0;
}

textarea{
    margin-left: 0%;
    position: relative;
    top: -0.2%;
}

.make-post-label{
    width: 100%;
    display: block;
    font-size: 4vw;
}

.make-post-input{
    display: block;
    margin-bottom: 3%;
    width: 30%;
    font-size: 4vw;
}

 .make-post-commission{
    width: 70%;
}

.make-post-description{
    height: 20vw;
    width: 70%;
 }

 .make-post-terms-and-cond{
    width: 70%;
    height: 20vw;
 }

 .make-post-location-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
 }

.make-post-image{
    width: 55%;

 }

 .make-post-submit{
    display: block;
    height: 5vw;
    width: 40%;
    height: 10vw;
    max-height: 60px;
    max-width: 300px;
    border-radius: 10%;
    color: black;
    margin-top: 4%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    font-size: 5vw;
    cursor: pointer;
}

.make-post-error, .make-post-buffer{
    font-size: 3vw;
    text-align: center;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 50%;
    margin-top: 0;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

.make-post-error{
    color: red;
}

.make-post-buffer{
    color: black;
}

@media screen and (min-width: 1000px) {

    .make-post-heading{
        font-size: 60px;
        margin-bottom: 0;
        margin-top: 4%;
    }

    .make-post-right, .make-post-left{
        width: 50%;
    }

    .make-post-input-box{
        width: 100%;
        padding-left: 10%;
    }

    .make-post-label{
        font-size: 35px;
    }
    
    .make-post-input{
        width: 50%;
        font-size: 35px;
    }

    .make-post-commission{
        width: 70%;
    }
    
    .make-post-description{
        width: 70%;
     }
    
     .make-post-terms-and-cond{
        width: 70%;
     }
    
    .make-post-image{
        width: 50%;
     }

     .make-post-submit{
        margin-top: 2%;
        font-size: 40px;
    }
}