.heading-box{
  margin-top: 1vw;
  margin-bottom: 3vw;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
}

.heading-image{
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 180px;
  height: 18vw;
}

.cash{
  margin-top: -3%;
}

.header-main{
  font-size: 5.5vw;
  width: 95%;
  padding-top: 2px;
  margin-left: auto;
  margin-right: auto;
}

.sub-text{
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.sub-text > p{
  font-size: 4.3vw;
  line-height: normal;
  text-align: center;
}

.new-para{
  display: block;
  margin-bottom: 2%;
  padding: 0;
}

.landingpage-break{
  display: block;
  margin: 0;
}


/*for demo purposes only*/
.demo > h1{
  font-size: 5vw;
  margin: 1%;
  cursor: pointer;
}

.demo-break{
  display: block;
}

.demo{
  width: 80%;
  border: solid black 1px;
  top: 4%;
  right: 2%;
  background-color:  #EAEDEC;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 4vw;
  line-height: 4vw;
  margin-top: 5%;
  line-height: 6vw;
}

ol{
  text-align: left;
  margin-left: 3%;
}


@media screen and (min-width: 1000px) {

  .demo > h1{
    font-size: 40px;
    margin: 0;
  }

  .demo{
    font-size: 30px;
  }
  /*ends here*/

  .header-main{
    font-size: 50px;
  }
    
  .sub-text > p{
    font-size: 30px;
  }

  .cash{
    margin-top: -30px;
  }
}