@import url(https://fonts.googleapis.com/css?family=Pacifico|Titan+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swa);
.nav-bar > *{
    box-sizing: border-box;
}

.nav-bar{
      background-color: rgb(251, 255, 36);
      position: fixed;
      width: 100%;
      height: 10vw;
      max-height: 90px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
}


.nav-bar > *{
    vertical-align: middle;
    font-size: 4vw;
}

.left-nav{
    position: absolute;
    top: 30%;
    left: 2%;
}

.main{
    font-family: 'Titan One', cursive;
}

.right-nav{
    position: absolute;
    width: 50%;
    text-align: right;
    padding-right: 4%;
    right: 0;
    top: 30%;
}

.register-link, .log-out-link{
    margin-left: 5%;
}

a{
    color: black;
    text-decoration: none;
}
  
@media screen and (min-width: 1000px) {
    .nav-bar > *{
        vertical-align: middle;
        font-size: 30px;
    }
}

.admin{
   z-index: 15;
   position: relative;
   margin-top: -2%;
   background-color: white;
}


.admin-header{
   padding-top: 5%;
   font-size: 5vw;
}

.admin-info{
    margin-left: auto;
    margin-right: auto;
    background-color: lightgray;
}
 
 .admin-info > *{
    font-size: 2vw;
 }

 .admin-paragraph{
   font-size: 1vw;
 }
 
 .admin-table{
    overflow-y:scroll;
    overflow-x:scroll;
    height: 30vw;
    display:block;
    text-align: left;
    border-collapse: collapse;
    margin-left: 0.2%;
 }
 
th{
   border: 1px solid black;
 }

.admin-delete, .admin-block{
   width: 10vw;
   font-size: 2vw;
 }
 
 .admin-return{
   margin-top: 5%;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 10%;
   display: block;
   border: black solid 2px;
   width: 20%;
   text-align: center;
   padding-top: 1%;
   background-color: yellow;
   height: 5vw;
   font-size: 2vw;
 }
 
 .admin-return:hover{
   background-color: brown;
 }
 
.admin-login-box{
    margin-top: 12%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10vw;
    width: 85%;
    max-width: 500px;
    text-align: center;
    border: #999090 solid 2px;
    background-color: #EAEDEC;
    border-radius: 10%;
}

.admin-login-box > *{
    font-size: 5vw;
}

.admin-login-header-box{
    text-align: center;
    position: relative;
    margin-bottom: 2%;
}

.admin-login-header{
    display: block;
    font-size: 8vw;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}


.admin-login-label, .admin-login-input{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}

.admin-login-label{
    margin-bottom: 2%;
    font-size: 5vw;
}

.admin-login-input{
    margin-bottom: 5%;
    margin-top: 0;
    width: 60%;
    height: 5.5vw;
    max-height: 45px;
    font-size: 3.5vw;
}


.admin-login-submit{
    margin: 2% auto;
    width: 35%;
    height: 8vw;
    max-height: 55px;
    vertical-align: middle;
    font-size: 4vw;
    border-radius: 10%;
    border: #999090 solid 1px;
    background-color: white;
    cursor: pointer;
}

.admin-login-submit:hover{
    background-color: #999090;
    transition: 0.3s;
}

.admin-login-submit:active{
    border-top: grey solid 2px;
    padding-top: 0.5%;
}

.go-back{
    display: block;
    margin: 5%;
    color: blue;
}
  
.go-back:hover{
    text-decoration: underline;
}

.admin-login-error{
    color: red;
    font-size: 4vw;
    margin: 0;
    margin-bottom: 5%;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1000px) {
  
    .admin-login-box > *{
      font-size: 30px;
    }

    .admin-login-header{
      font-size: 55px;
    }

    .admin-login-label, .admin-login-input{
      font-size: 30px;
    }

    .admin-login-submit{
      font-size: 30px;
    }
}

.edit-post{
    margin-top: 11%;
}

.edit-post-heading{
    font-size: 7vw;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}

.edit-post-form{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    padding-bottom: 2%;
    position: relative;
    background-color: #EAEDEC;
    border: #999090 solid 2px;
    border-radius: 10%;
}

.edit-post-input-box{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
}


.edit-post-right, .edit-post-left{
    width: 100%;
    min-width: 250px;
    margin: 0;
}

textarea{
    margin-left: 0%;
    position: relative;
    top: -0.2%;
}

.edit-post-label{
    width: 100%;
    display: block;
    font-size: 4vw;
}

.edit-post-input{
    display: block;
    margin-bottom: 3%;
    width: 30%;
    font-size: 4vw;
}

 .edit-post-commission{
    width: 70%;
}

.edit-post-description{
    height: 20vw;
    width: 70%;
 }

 .edit-post-terms-and-cond{
    width: 70%;
    height: 20vw;
 }

 .edit-post-location-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
 }

.edit-post-image{
    width: 55%;

 }

 .edit-post-submit{
    display: block;
    height: 5vw;
    width: 40%;
    height: 10vw;
    max-height: 60px;
    max-width: 300px;
    border-radius: 10%;
    color: black;
    margin-top: 4%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    font-size: 5vw;
    cursor: pointer;
}

.edit-post-error, .edit-post-buffer{
    font-size: 3vw;
    text-align: center;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 50%;
    margin-top: 0;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

.edit-post-error{
    color: red;
}

.edit-post-buffer{
    color: black;
}

@media screen and (min-width: 1000px) {

    .edit-post-heading{
        font-size: 60px;
        margin-bottom: 0;
        margin-top: 4%;
    }

    .edit-post-right, .edit-post-left{
        width: 50%;
    }

    .edit-post-input-box{
        width: 100%;
        padding-left: 10%;
    }

    .edit-post-label{
        font-size: 35px;
    }
    
    .edit-post-input{
        width: 50%;
        font-size: 35px;
    }

    .edit-post-commission{
        width: 70%;
    }
    
    .edit-post-description{
        width: 70%;
     }
    
     .edit-post-terms-and-cond{
        width: 70%;
     }
    
    .edit-post-image{
        width: 50%;
     }

     .edit-post-submit{
        margin-top: 2%;
        font-size: 40px;
    }
}
.hero{
    position: relative;
    margin-top: 15%;
    height: 48vw;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: none !important;
}

.landing-page-get-started-link-mobile{
    text-align: center;
    margin: auto;
    padding: 2%;
    position: absolute;
    left: 40%;
    width: 20%;
    text-align: center;
    font-size: 2.5vw;
    background-color: #886060;
    border-radius: 30%;
    border: 1px solid black;
    z-index: 8;
}

.landing-page-get-started-link{
    display: none;
}

.landing-page-get-started-link:hover{
    color: #886060;
    background-color: yellow;
}

.salesman{
    max-height: 550px;
    height: 45vw;
    position: absolute;
    bottom: 0;
    z-index: 3;
    left: 20%;
    opacity: 0;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.yellow-car{
    max-height: 400px;
    height: 30vw;
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: -60%;
    -webkit-animation-name: car-drives-in;
            animation-name: car-drives-in;
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes fade-in {
    from{ left: 17%; }
    to { left: 20%; opacity: 1;}
}

@keyframes fade-in {
    from{ left: 17%; }
    to { left: 20%; opacity: 1;}
}

@-webkit-keyframes car-drives-in {
    from{ right: -60%;}
    to { right: 20%; }
}

@keyframes car-drives-in {
    from{ right: -60%;}
    to { right: 20%; }
}

@-webkit-keyframes emerge {
    from { bottom: 8%; }
    to { bottom: 80%; opacity: 1; }
}

@keyframes emerge {
    from { bottom: 8%; }
    to { bottom: 80%; opacity: 1; }
}

@media screen and (min-width: 1000px) {
  
    .hero{
        margin-top: 10%;
    }

    .landing-page-get-started-link-mobile{
        display: none;
    }

    .landing-page-get-started-link{
        display:  block;
        text-align: center;
        margin: auto;
        padding: 2%;
        z-index: 1;
        position: absolute;
        opacity: 0;
        right: 40%;
        width: 20%;
        font-size: 2.5vw;
        background-color: #886060;
        border-radius: 30%;
        -webkit-animation-name: emerge;
                animation-name: emerge;
        -webkit-animation-duration: 3s;
                animation-duration: 3s;
        -webkit-animation-delay: 3s;
                animation-delay: 3s;
        -webkit-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
        transition: 1s;
        font-family: 'Merriweather Sans', sans-serif;
        border: 1px solid black;
    }
}

@media screen and (min-width: 1600px) {
    @-webkit-keyframes emerge {
        from { bottom: 8%; }
        to { bottom: 60%; opacity: 1; }
    }
    @keyframes emerge {
        from { bottom: 8%; }
        to { bottom: 60%; opacity: 1; }
    }

    .hero{
        margin-top: 0;
    }
}
.heading-box{
  margin-top: 1vw;
  margin-bottom: 3vw;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
}

.heading-image{
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 180px;
  height: 18vw;
}

.cash{
  margin-top: -3%;
}

.header-main{
  font-size: 5.5vw;
  width: 95%;
  padding-top: 2px;
  margin-left: auto;
  margin-right: auto;
}

.sub-text{
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.sub-text > p{
  font-size: 4.3vw;
  line-height: normal;
  text-align: center;
}

.new-para{
  display: block;
  margin-bottom: 2%;
  padding: 0;
}

.landingpage-break{
  display: block;
  margin: 0;
}


/*for demo purposes only*/
.demo > h1{
  font-size: 5vw;
  margin: 1%;
  cursor: pointer;
}

.demo-break{
  display: block;
}

.demo{
  width: 80%;
  border: solid black 1px;
  top: 4%;
  right: 2%;
  background-color:  #EAEDEC;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 4vw;
  line-height: 4vw;
  margin-top: 5%;
  line-height: 6vw;
}

ol{
  text-align: left;
  margin-left: 3%;
}


@media screen and (min-width: 1000px) {

  .demo > h1{
    font-size: 40px;
    margin: 0;
  }

  .demo{
    font-size: 30px;
  }
  /*ends here*/

  .header-main{
    font-size: 50px;
  }
    
  .sub-text > p{
    font-size: 30px;
  }

  .cash{
    margin-top: -30px;
  }
}
.auth-boxes > *{
  box-sizing: border-box;
}

.auth-boxes > div{
  background-color: #EAEDEC;
  border: #999090 solid 2px;
}
  
.auth-boxes{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}
  
.Login-register-admin{
  margin-top: 5%;
  margin-bottom: 5%;
  font-size: 2vw;
  text-align: left;
  width: 70%;
  padding-bottom: 2%;
}

.Login-register-admin > p{
 text-align: center;
 line-height: normal;
 margin: auto;
}

.mini-login-header{
 text-align: center;
 margin-top: 2%;
 font-size: 6.5vw;
 margin-top: 3%;
 margin-bottom: 0;
}

.mini-login-label, .mini-login-input{
 display: block;
 margin-right: auto;
 margin-left: auto;
 width: 70%;
 margin-bottom: 1%;
 background-color: 999090;
}
  
.mini-login-label{
  font-size: 5vw;
  margin-top: 4%;
}

.mini-login-input{
  font-size: 5vw;
}

.mini-login-register, .mini-login-admin{
  font-size: 4.5vw;
  padding-top: 2%;
}


.mini-login-link{
  display: block;
  color: blue;
  margin-top: 2%;
}

.mini-login-link:hover{
  text-decoration: underline;
}

.mini-login-submit{
  text-align: center;
  font-size: 5vw;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 10%;
  border: #999090 solid 1px;
  background-color: white;
}

.mini-login-submit:hover{
  background-color: #999090;
  transition: 0.3s;
  cursor: pointer;
}

.mini-login-submit:active{
  border-top: grey solid 2px;
  background-color: #999090;
  padding-top: 0.5%;
}

.mini-login-register-heading{
  font-size: 5vw;
  width: 90%; 
  margin-left: auto;
  margin-right: auto;
}

.additional-info{
    display: none;
}

.additional-info > p{
  font-size: 2vw;
  text-align: center;
}
  

.additional-info-mobile{
  width: 70%;
  text-align: center;
  padding-bottom: 5%;
  margin-top: 3%;
  margin-bottom: 4%;
}

.additional-info-mobile > *{
  font-size: 4.5vw;
  line-height: 2.5vw;
}

.additional-info > img{
  height: 3vw;
  width: 3vw;
}

.image-link-container-mobile > a{
  margin-left: 2vw;
  font-weight: bold;
  color: blue;
}

.auth-box-mobile-link:hover{
  text-decoration: underline;
}

.auth-box-break{
  display: block;
  margin-top: 1%;
  padding: 0;
}

.mini-login-admin{
  padding-top: 1vw;
}

.github, .linkedIn{
  width: 30%;
  margin: 10%;
}

.image-link-container{
  text-align: center;
  width: 100%;
  display: block;
}

.portfolio-link{
  font-weight: bold;
  color: blue;
  margin-top: 1rem;
  margin-bottom: 0;
}

.portfolio-link:hover{
  text-decoration: underline;
}

@media screen and (min-width: 1000px) {
  .auth-boxes.mobile{
    display: none;
  }
  
  .Login-register-admin{
    font-size: 2vw;
    text-align: left;
    width: 50%;
    padding-bottom: 2%;
    height: 37vw;
    min-height: 430px;
    max-height: 680px;
  }

  .additional-info{
    display: block;
    min-height: 430px;
    height: 37vw;
    max-height: 680px;
    width: 30%;
  }


  .mini-login-header{
    font-size: 3vw;
   }

   .mini-login-label{
    font-size: 2.2vw;
  }
  
  .mini-login-input{
    font-size: 2.5vw;
  }

  .mini-login-register, .mini-login-admin{
    font-size: 2vw;
    padding-top: 0;
  }

  .mini-login-submit{
     font-size: 2.2vw;
     
  }
}

@media screen and (min-width: 1800px) {
  .additional-info > p{
    font-size: 34px;
    text-align: center;
  }

  .mini-login-header{
    font-size: 45px;
   }

   .mini-login-label{
    font-size: 40px;
  }
  
  .mini-login-input{
    font-size: 40px;
  }

  .mini-login-register, .mini-login-admin{
    font-size: 40px;
    padding-top: 0;
  }

  .mini-login-submit{
    font-size: 40px;
    padding: 5px;   
  }
  
}
.landing-page{
  margin-top: 11%;
}

.landing-page > section{
  padding: 1.5%;
  width: 95%;
  max-width: 1200px;
  margin: 5% auto;
  background-color: rgba(237, 247, 102, 0.952);
  border: #886060 0.5px solid;
  border-radius: 100px;
}

.landing-page-link{
  text-decoration: none;
}

@media screen and (min-width: 1000px) {
  
  .landing-page > section{
    padding: 0.2%;
  }

  .landing-page{
    margin-top: 11%;
  }
    
}

@media screen and (min-width: 1400px) {
  .landing-page{
    margin-top: 0;
  }
}
.login-box{
    margin-top: 12%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10vw;
    width: 85%;
    max-width: 500px;
    text-align: center;
    border: #999090 solid 2px;
    background-color: #EAEDEC;
    border-radius: 10%;
}

.login-box > *{
    font-size: 5vw;
}

.login-header-box{
    text-align: center;
    position: relative;
    margin-bottom: 2%;
}

.login-header{
    display: block;
    font-size: 8vw;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}


.login-label, .login-input{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
}

.login-label{
    margin-bottom: 2%;
    font-size: 5.5vw;
}

.login-input{
    margin-bottom: 5%;
    margin-top: 0;
    width: 60%;
    font-size: 4.5vw;
}
  
.login-submit{
    margin: 2% auto;
    width: 35%;
    height: 8vw;
    max-height: 55px;
    vertical-align: middle;
    font-size: 4vw;
    border-radius: 10%;
    border: #999090 solid 1px;
    background-color: white;
    cursor: pointer;
}

.login-submit:hover{
    background-color: #999090;
    transition: 0.3s;
}

.login-submit:active{
    border-top: grey solid 2px;
    padding-top: 0.5%;
}

.login-register-heading{
    font-size: 5vw;
    width: 90%; 
    margin-left: auto;
    margin-right: auto;
}

.login-register-link{
    display: block;
    margin-top: 2%;
    color: blue;
}

.login-register-link:hover{
    text-decoration: underline;
}

.login-error{
    color: red;
    font-size: 4vw;
    margin: 0;
    margin-bottom: 5%;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
  
@media screen and (min-width: 1000px) {
  
      .login-box > *{
        font-size: 30px;
      }
  
      .login-header{
        font-size: 55px;
      }

      .login-label, .login-input{
        font-size: 30px;
      }

      .login-submit{
        font-size: 30px;
    }
}

.make-post{
    margin-top: 11%;
}

.make-post-heading{
    font-size: 7vw;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 6%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}

.make-post-form{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10%;
    padding-bottom: 2%;
    position: relative;
    background-color: #EAEDEC;
    border: #999090 solid 2px;
    border-radius: 10%;
}

.make-post-input-box{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: stretch;
}


.make-post-right, .make-post-left{
    width: 100%;
    min-width: 250px;
    margin: 0;
}

textarea{
    margin-left: 0%;
    position: relative;
    top: -0.2%;
}

.make-post-label{
    width: 100%;
    display: block;
    font-size: 4vw;
}

.make-post-input{
    display: block;
    margin-bottom: 3%;
    width: 30%;
    font-size: 4vw;
}

 .make-post-commission{
    width: 70%;
}

.make-post-description{
    height: 20vw;
    width: 70%;
 }

 .make-post-terms-and-cond{
    width: 70%;
    height: 20vw;
 }

 .make-post-location-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 80%;
 }

.make-post-image{
    width: 55%;

 }

 .make-post-submit{
    display: block;
    height: 5vw;
    width: 40%;
    height: 10vw;
    max-height: 60px;
    max-width: 300px;
    border-radius: 10%;
    color: black;
    margin-top: 4%;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    font-size: 5vw;
    cursor: pointer;
}

.make-post-error, .make-post-buffer{
    font-size: 3vw;
    text-align: center;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 50%;
    margin-top: 0;
    margin-bottom: 2%;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

.make-post-error{
    color: red;
}

.make-post-buffer{
    color: black;
}

@media screen and (min-width: 1000px) {

    .make-post-heading{
        font-size: 60px;
        margin-bottom: 0;
        margin-top: 4%;
    }

    .make-post-right, .make-post-left{
        width: 50%;
    }

    .make-post-input-box{
        width: 100%;
        padding-left: 10%;
    }

    .make-post-label{
        font-size: 35px;
    }
    
    .make-post-input{
        width: 50%;
        font-size: 35px;
    }

    .make-post-commission{
        width: 70%;
    }
    
    .make-post-description{
        width: 70%;
     }
    
     .make-post-terms-and-cond{
        width: 70%;
     }
    
    .make-post-image{
        width: 50%;
     }

     .make-post-submit{
        margin-top: 2%;
        font-size: 40px;
    }
}
.view-my-posts-link{
  color: black;
  top: 10%;
  text-decoration: none;
  position: relative;
  top: 8vw
}

.view-my-posts-post-box{
  margin-top: 12%;
  margin-bottom: 5%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  align-content: space-around;
  flex-wrap: wrap;
}

.view-my-posts-create-post{
  text-align: center;
  margin-top: 10%;
}

.view-my-posts-create-post-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 4vw;
  margin-bottom: 8%;
  background-color: rgba(237, 247, 102, 0.952);
  border-radius: 10%;
  border: 1px solid #3c484d;
  font-weight: bold;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 2%;
  padding-right: 2%;
}

.view-my-posts-create-post-button:hover{
  background-color: #996060;
  transition: 0.5s;
  cursor: pointer;
}


.view-my-posts-link{
  color: black;
  text-decoration: none;
}


@media screen and (min-width: 1000px) {
  .view-my-posts-post-box{
      margin-top: 6%;
  }

  
  .view-my-posts-create-post-button{
    font-size: 2.5vw;
    padding-top: 1%;
    padding-left: 2%;
    padding-bottom: 1%;
  }
}
.view-my-posts-post{
  width: 40%;
  border-radius: 20px;
  margin: 1%;
  margin-top: 6%;
  background-color: rgba(237, 247, 102, 0.952);
  border: #999090 1px solid;
}

.view-my-posts-car-name{
  text-align: center;
  width: 100%;
  font-size: 3.5vw;
  margin-bottom: 3%;
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: bold;
}

.view-my-posts-car-image-box{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 35%;
}

.view-my-posts-car-image{
  width: 100%;
  border: #999090 1px solid;
}

.view-my-posts-post > ul{
  list-style: none;
  padding: 0;
  text-align: center;
  margin: 0;
  width: 100%;
}

.view-my-posts-lists  li{
  font-family: 'Merriweather Sans', sans-serif;
}

ul .view-my-posts-car-location{
  font-size: 3vw;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  margin-top: -3px;
  margin-bottom: 0;
}

ul .view-my-posts-car-price{
  font-size: 5vw;
  margin: 5%;
}

ul .view-my-posts-car-commission{
  font-size: 4vw;
  padding-left: 5%;
  padding-right: 5%;
  margin: 1%;
  margin-bottom: 10%;
}

.view-my-post_commision-title{
    display: block;
    font-family: 'Merriweather Sans', sans-serif;
    text-decoration: underline;
}

.view-my-posts-lists > li{
    font-size: 3vw;
    margin-bottom: 1%;
}

.view-my-posts-post{
  text-decoration: none;
  color: black;
}

.view-my-posts-button{
  width: 50%;
  max-width: 150px;
  max-height: 50px;
  font-size: 3vw;
  margin-bottom: 5%;
}

.view-my-confirm-delete{
  font-size: 4vw;
  margin-bottom: 8%;
  margin-top: 0;
  color: red;
}

@media screen and (min-width: 1000px) {
  .view-my-posts-post{
    width: 25%;
  }

  .view-my-posts-post:hover{
    background-color: yellow;
    transition: 0.4s;
  }

  .view-my-posts-car-name{
    font-size: 30px;
    margin-bottom: 3%;
  }

  .view-my-posts-lists > li{
    font-size: 30px;
  }

  ul .view-my-posts-car-price{
    font-size: 40px;
    margin: 0;
  }

  ul .view-my-posts-car-commission{
    font-size: 30px;
  }

  ul .view-my-posts-car-location{
    font-size: 20px;
  }

  .view-my-posts-button{
    font-size: 30px;
  }

  .view-my-confirm-delete{
    font-size: 30px;
  }
}
.register-box{
    margin-top: 12%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10vw;
    width: 85%;
    max-width: 500px;
    text-align: center;
    border: #999090 solid 2px;
    background-color: #EAEDEC;
    border-radius: 10%;
}

.register-box > *{
    font-size: 5vw;
}

.register-header-box{
    text-align: center;
    position: relative;
    margin-bottom: 2%;
}

.register-header{
    display: block;
    font-size: 8vw;
    margin-top: 5%;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
}

.register-label, .register-input{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
}

.register-label{
    margin-bottom: 1%;
    font-size: 5vw;
}

.register-input{
    margin-bottom: 5%;
    margin-top: 0;
    width: 70%;
    font-size: 4.5vw;
}

.register-label{
    margin-bottom: 2%;
    font-size: 5.5vw;
}
  
.register-submit{
    margin: 2% auto;
    margin-bottom: 5%;
    width: 35%;
    height: 8vw;
    max-height: 55px;
    vertical-align: middle;
    font-size: 4vw;
    border-radius: 10%;
    border: #999090 solid 1px;
    background-color: white;
    cursor: pointer;
}

.register-submit:hover{
    background-color: #999090;
    transition: 0.3s;
}

.register-submit:active{
    border-top: grey solid 2px;
    background-color: #999090;
    padding-top: 0.5%;
}


.register-error{
    color: red;
    font-size: 4vw;
    margin: 0;
    margin-bottom: 5%;
    border: 1px solid #999090;
    background-color: #DFE32E;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.register-login-heading{
    font-size: 5vw;
    width: 90%; 
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
}

.register-login-link{
    display: block;
    margin-top: 2%;
    color: blue;
}

.register-login-link:hover{
    text-decoration: underline;
}

  
@media screen and (min-width: 1000px) {
  
      .register-box > *{
        font-size: 30px;
      }
  
      .register-header{
        font-size: 55px;
      }

      .register-label, .register-input{
        font-size: 30px;
      }

      .register-error{
        font-size: 30px;
      }

      .register-submit{
          font-size: 30px;
      }  
}

.view-post-box{
  margin-top: 15%;
  margin-bottom: 10%;
  padding-top: 2%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  background-color: rgba(237, 247, 102, 0.952);
  border-radius: 5%;
}

.view-post-image{
  text-align: center;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  display: block;
}

.view-post-image{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 800px;
  border: #999090 1px solid;
}

.view-post-carInfo-name, .view-post-carInfo-price, .view-post-carInfo-commission{
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-family: 'Merriweather Sans', sans-serif;
}

.view-post-carInfo-name{
  font-size: 7vw;
  margin-top: 3%;
  margin-bottom: 0;
}

.view-post-carInfo-price{
  font-size: 8vw;
  margin-top: 2%;
  margin-bottom: 1%;
}

.view-post-carInfo-commission{
  font-size: 5vw;
}

.view-post-info{
  list-style: none;
  font-size:  4vw;
  padding-left: 10%;
}

.view-post-text-box-label{
  font-weight: 600;
  font-size: 4.5vw;
}

.view-post-text-box{
  border: #999090 1px solid;
  background-color: #EAEDEC;
  width: 85%;
  font-size: 4.5vw;
  padding-left: 1%;
  margin-bottom: 2%;
}

.view-post-text-box.description, .view-post-text-box.terms-cond{
  max-height: 300px;
  height: 35vw;
  overflow-y: auto;
}

.view-post-contact-button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
  font-size: 3vw;
  width: 30%;
  height: 10vw;
  border-radius: 10%;
  border: #999090 1px solid;
  background-color: #EAEDEC;
  cursor: pointer;
}

.view-post-contact-button:hover{
  background-color: #999090;
  transition: 1s;
}

.view-post-contact-button:visited{
  background-color: #999090;
}

.view-post-user-email{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 2%;
  text-align: center;
  font-size: 4vw;
  width: 50%;
  border: #999090 1px solid;
  background-color: #EAEDEC;
  overflow-x: auto;
}

@media screen and (min-width: 1000px) {

  .view-post-box{
    margin-top: 10vw;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .view-post-text-box{
    width: 50%;
    font-size: 1.5vw;
    padding-left: 1%;
    margin-bottom: 2%;
  }

  .view-post-text-box-label{
    font-size: 1.5vw;
  }

  .view-post-carInfo-name{
    font-size: 3vw;
  } 

  .view-post-carInfo-price{
    text-align: center;
    font-size: 2.7vw;
    width: 80%;  
  }

  .view-post-carInfo-commission{
    font-size: 25px;
  }

  .view-post-info{
    list-style: none;
    font-size:  0;
    padding-left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .view-post-image{
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    display: block;
  }
  
  .view-post-image{
    width: 85%;
    max-width: 800px;
    border: #999090 1px solid;
  }

  .view-post-desk-top-view-box{
    width: 45%;
  }

  .view-post-desk-top-view-box-two{
    width: 55%;
    margin-top: 2%;
  }

  .view-post-desk-top-view-list-box{
    width: 50%;
  }

  .view-post-desk-top-view-list-box-two{
    width: 50%;
  }
  
  .view-post-text-box{
    width: 90%;
  }

  .view-post-contact-button{
    font-size: 20px;
    height: 50px;
    margin-top: 1%;
    margin-bottom: 2%;
    width: 300px;
    border-radius: 10%;
    border: #999090 1px solid;
    background-color: #EAEDEC;
  }

  .view-post-user-email{
    display: block;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 0;
    margin-bottom: 2%;
    text-align: center;
    font-size: 30px;
    width: 40%;
    border: #999090 3px solid;
    background-color: #EAEDEC;
    overflow-x: auto;
  }
}

.view-posts-link{
  color: black;
  top: 10%;
  text-decoration: none;
  position: relative;
  top: 8vw;
}

.view-posts-post-box{
    width: 100%;
    margin-top: 0;
    margin-bottom: 8%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    align-content: space-around;
    flex-wrap: wrap;
  }
  
  
  .view-posts-create-post-button{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 4vw;
    margin-bottom: 8%;
    background-color: rgba(237, 247, 102, 0.952);
    border-radius: 10%;
    border: 1px solid #3c484d;
    font-weight: bold;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .view-posts-create-post-button:hover{
    background-color: #996060;
    transition: 0.5s;
    cursor: pointer;
  }

@media screen and (min-width: 1000px) {
    .view-posts-post-box{
        margin-top: 6%;
    }

    .view-posts-create-post-button{
      font-size: 2vw;
      padding-top: 1%;
      padding-left: 2%;
      padding-bottom: 1%;
    }
}
   .view-posts-post{
    width: 40%;
    border-radius: 20px;
    margin: 1%;
    margin-top: 6%;
    background-color: rgba(237, 247, 102, 0.952);
    border: #999090 1px solid;
  }

  .view-posts-car-name{
    text-align: center;
    width: 100%;
    font-size: 3.5vw;
    margin-bottom: 3%;
    font-family: 'Merriweather Sans', sans-serif;
    font-weight: bold;
  }
  
  .view-posts-car-image-box{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 35%;
  }

  .view-posts-car-image{
    width: 100%;
    border: #999090 1px solid;
  }

  .view-posts-post > ul{
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 0;
    width: 100%;
 }

  .view-posts-lists  li{
    font-family: 'Merriweather Sans', sans-serif;
  }

  ul .view-posts-car-location{
    font-size: 3vw;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-top: -3px;
    margin-bottom: 0;
  }

  ul .view-posts-car-price{
    font-size: 5vw;
    margin: 5%;
  }

  ul .view-posts-car-commission{
    font-size: 4vw;
    padding-left: 5%;
    padding-right: 5%;
    margin: 1%;
    margin-bottom: 10%;
  }

  .view-post_commision-title{
    display: block;
    font-family: 'Merriweather Sans', sans-serif;
    text-decoration: underline;
  }
  
  .view-posts-lists > li{
      font-size: 3vw;
      margin-bottom: 1%;
  }

  .view-posts-post{
    text-decoration: none;
    color: black;
  }

  .post-link{
    height: auto;
    width: auto;
  }

  @media screen and (min-width: 1000px) {
    .view-posts-post{
      width: 25%;
    }

    .view-posts-post:hover{
      background-color: yellow;
      position: relative;
      bottom: 10px;
      transition: 1s;
    }

    .view-posts-car-name{
      font-size: 30px;
      margin-bottom: 3%;
    }

    .view-posts-lists > li{
      font-size: 30px;
    }

    ul .view-posts-car-price{
      font-size: 40px;
      margin: 0;
    }

    ul .view-posts-car-commission{
      font-size: 30px;
    }

    ul .view-posts-car-location{
      font-size: 20px;
    }
  }
.no-page-found{
    margin-top: 20%;
    text-align: center;
}
.App{
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 2.5vw;
}

footer{
      background-color: rgba(246, 250, 19, 0.979);
      display: block;
      height: 5vw;
      font-size: 2.3vw;
      text-align: right;
      padding-top: 1%;
      padding-right: 5px;
      position: absolute;
      bottom: 0;
      width: 100%;
}

@media screen and (min-width: 1000px) {
    footer{ 
        font-size: 25px;
        padding: 1.5%; 
    }
}
* {
  box-sizing: border-box;
 }
 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(/static/media/car-fade.56fe58d2.png);
  background-repeat: repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  height: 100%;
  position: relative;
}

*{
  font-family: 'Roboto', sans-serif;
}
