@import url('https://fonts.googleapis.com/css?family=Merriweather+Sans&display=swap');

.App{
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 2.5vw;
}

footer{
      background-color: rgba(246, 250, 19, 0.979);
      display: block;
      height: 5vw;
      font-size: 2.3vw;
      text-align: right;
      padding-top: 1%;
      padding-right: 5px;
      position: absolute;
      bottom: 0;
      width: 100%;
}

@media screen and (min-width: 1000px) {
    footer{ 
        font-size: 25px;
        padding: 1.5%; 
    }
}