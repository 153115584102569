
.admin{
   z-index: 15;
   position: relative;
   margin-top: -2%;
   background-color: white;
}


.admin-header{
   padding-top: 5%;
   font-size: 5vw;
}

.admin-info{
    margin-left: auto;
    margin-right: auto;
    background-color: lightgray;
}
 
 .admin-info > *{
    font-size: 2vw;
 }

 .admin-paragraph{
   font-size: 1vw;
 }
 
 .admin-table{
    overflow-y:scroll;
    overflow-x:scroll;
    height: 30vw;
    display:block;
    text-align: left;
    border-collapse: collapse;
    margin-left: 0.2%;
 }
 
th{
   border: 1px solid black;
 }

.admin-delete, .admin-block{
   width: 10vw;
   font-size: 2vw;
 }
 
 .admin-return{
   margin-top: 5%;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 10%;
   display: block;
   border: black solid 2px;
   width: 20%;
   text-align: center;
   padding-top: 1%;
   background-color: yellow;
   height: 5vw;
   font-size: 2vw;
 }
 
 .admin-return:hover{
   background-color: brown;
 }
 