@import url('https://fonts.googleapis.com/css?family=Pacifico|Titan+One&display=swap');

.nav-bar > *{
    box-sizing: border-box;
}

.nav-bar{
      background-color: rgb(251, 255, 36);
      position: fixed;
      width: 100%;
      height: 10vw;
      max-height: 90px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
}


.nav-bar > *{
    vertical-align: middle;
    font-size: 4vw;
}

.left-nav{
    position: absolute;
    top: 30%;
    left: 2%;
}

.main{
    font-family: 'Titan One', cursive;
}

.right-nav{
    position: absolute;
    width: 50%;
    text-align: right;
    padding-right: 4%;
    right: 0;
    top: 30%;
}

.register-link, .log-out-link{
    margin-left: 5%;
}

a{
    color: black;
    text-decoration: none;
}
  
@media screen and (min-width: 1000px) {
    .nav-bar > *{
        vertical-align: middle;
        font-size: 30px;
    }
}